import {
    TEMPLATES_ERROR,
    TEMPLATES_FETCH
} from '../actions/templates';

import updateObject from "../updateObject";

const initialState = {
    error: "",
    data: {},
};

export function templatesFetch(state, action) {
    return updateObject(state, {
        data: action.payload
    });
}

export function templatesError(state, action) {
    return updateObject(state, {
        error: action.payload
    });
}

export function templatesReducer(state = initialState, action) {
    switch (action.type) {
        case TEMPLATES_FETCH: return templatesFetch(state, action);
        case TEMPLATES_ERROR: return templatesError(state, action);
        default: return state;
    }
}