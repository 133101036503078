import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import reportWebVitals from "./reportWebVitals";
import Application from "./components/pages";

import { Provider } from "react-redux";
import store from './redux/store';

import "./sass/index.css";

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Application />
        </Provider>
    </BrowserRouter>
);


reportWebVitals();
