import axios from "axios";

export const TEMPLATES_FETCH = 'TEMPLATES_FETCH';
export const TEMPLATES_ERROR = 'TEMPLATES_ERROR';

export function templatesError(payload) {
    return {
        type: TEMPLATES_ERROR,
        payload: payload
    };
}

export function templatesFetch(payload) {
    return {
        type: TEMPLATES_FETCH,
        payload: payload
    };
}

export const getTemplates = (props) => {
    return async (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_KEY}/templates?token=${localStorage.getItem("authToken")}`)
        .then(res => {
            dispatch(templatesFetch(res.data));
        }).catch(err => {
            dispatch(templatesError(err.response.data.reason));
        });
    }
};
