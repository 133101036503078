import axios from "axios";

export const PROJECTS_FETCH = 'PROJECTS_FETCH';
export const PROJECTS_FORM = 'PROJECTS_FORM';
export const PROJECTS_POST = 'PROJECTS_POST';
export const PROJECTS_PUT = 'PROJECTS_PUT';
export const PROJECTS_ERROR = 'PROJECTS_ERROR';

export function projectsError(payload) {
    return {
        type: PROJECTS_ERROR,
        payload: payload
    };
}

export function projectsFetch(payload) {
    return {
        type: PROJECTS_FETCH,
        payload: payload
    };
}

export function projectsForm(payload) {
    return {
        type: PROJECTS_FORM,
        payload: payload
    };
}

export function projectsPost(payload) {
    return {
        type: PROJECTS_POST,
        payload: payload
    };
}

export function projectsPut(payload) {
    return {
        type: PROJECTS_PUT,
        payload: payload
    };
}

export const submitProjectForm = (props) => {
    return async (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_KEY}/projects/initialize`, {
            id: props.id,
            data: props.data
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + localStorage.getItem('authToken')
            }
        })
            .then(res => {
                window.location.reload();
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};

export const generateProject = (props) => {
    return async (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_KEY}/projects/generate?id=${props?.id}`)
            .then(res => {
                window.location.reload();
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};

export const getProjectForm = (props) => {
    return async (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_KEY}/projects/initialize`, {
            id: props.id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + localStorage.getItem('authToken')
            }
        })
            .then(res => {
                dispatch(projectsForm(res.data));
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};

export const getProjects = (props) => {
    return async (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_KEY}/projects?token=${localStorage.getItem("authToken")}`)
            .then(res => {
                dispatch(projectsFetch(res.data));
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};

export const downloadProject = (props) => {
    return async (dispatch) => {
        axios({
            url: `${process.env.REACT_APP_API_KEY}/cloud/download?project=${props.id}`, //your url
            method: 'GET',
            responseType: 'blob', // important
        })
            .then(response => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'archive.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};

export const createProject = (props) => {
    return async (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_KEY}/projects`, {
            token: localStorage.getItem("authToken"),
            name: props.name,
            content: {
                templateId: props.templateId,
                description: props.description
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Token " + localStorage.getItem("authToken")
            }
        })
            .then(res => {
                dispatch(projectsPost(res.data));
                window.location.href = "/project?id=" + res.data.id;
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};

export const updateProject = (props) => {
    return async (dispatch) => {
        axios.put(`${process.env.REACT_APP_API_KEY}/projects`, {
            token: localStorage.getItem("authToken"),
            name: props.name,
            content: props.content
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Token " + localStorage.getItem("authToken")
            }
        })
            .then(res => {
                dispatch(projectsPut(res.data));
                window.location.reload();
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};

export const deleteProject = (props) => {
    return async (dispatch) => {
        axios.delete(`${process.env.REACT_APP_API_KEY}/projects`, {
            token: localStorage.getItem("authToken"),
            name: props.name,
            content: props.content
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Token " + localStorage.getItem("authToken")
            }
        })
            .then(res => {
                window.location.href = "/projects";
            }).catch(err => {
                dispatch(projectsError(err.response.data.reason));
            });
    }
};
