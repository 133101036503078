import {
    CHAT_ERROR,
    CHAT_FETCH,
    CHAT_POST
} from '../actions/chat';

import updateObject from "../updateObject";

const initialState = {
    error: "",
    data: {},
};

export function chatFetch(state, action) {
    localStorage.setItem("lastChat", action.payload.id);
    return updateObject(state, {
        data: action.payload
    });
}

export function chatError(state, action) {
    return updateObject(state, {
        error: action.payload
    });
}

export function chatPost(state, action) {
    return updateObject(state, {
        data: action.payload
    });
}

export function chatReducer(state = initialState, action) {
    switch (action.type) {
        case CHAT_FETCH: return chatFetch(state, action);
        case CHAT_ERROR: return chatError(state, action);
        case CHAT_POST: return chatPost(state, action);
        default: return state;
    }
}