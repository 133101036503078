import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { Loader } from "../common/utils/Main";

import BackgroundElement1 from '../../assets/bg_element1.png';
import BackgroundElement2 from '../../assets/bg_element2.png';
import { useDispatch } from "react-redux";
import { checkin } from "../../redux/actions/me";
import { getProjects } from "../../redux/actions/projects";
import { getTemplates } from "../../redux/actions/templates";

const Home = lazy(() => delayForClient(import("./Home")));
const Chat = lazy(() => delayForClient(import("./Chat")));
const Project = lazy(() => delayForClient(import("./Project")));
const ProjectNew = lazy(() => delayForClient(import("./ProjectNew")));
const Profile = lazy(() => delayForClient(import("./Profile")));
const Login = lazy(() => delayForClient(import("./Login")));
const Register = lazy(() => delayForClient(import("./Register")));

function delayForClient(promise) {
    return new Promise(resolve => {
        setTimeout(resolve, 2500);
    }).then(() => promise);
}

const Application = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("authToken")) {
            dispatch(checkin());
            dispatch(getProjects());
            dispatch(getTemplates());
            console.log('Checking in...');
        }
    });

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/chat" element={<Chat />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/project" element={<Project />} />
                <Route exact path="/project-new" element={<ProjectNew />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
            </Routes>

            <img src={BackgroundElement1} className='background__asset--first' alt='Background asset.' />
            <img src={BackgroundElement2} className='background__asset--second' alt='Background asset.' />
        </Suspense>
    );
};

export default Application;