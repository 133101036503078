import axios from "axios";

export const CHAT_FETCH = 'CHAT_FETCH';
export const CHAT_POST = 'CHAT_POST';
export const CHAT_ERROR = 'CHAT_ERROR';

export function chatError(payload) {
    return {
        type: CHAT_ERROR,
        payload: payload
    };
}

export function chatFetch(payload) {
    return {
        type: CHAT_FETCH,
        payload: payload
    };
}

export function chatPost(payload) {
    return {
        type: CHAT_POST,
        payload: payload
    };
}

export const getChat = (props) => {
    return async (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_KEY}/chat?id=${props.id}`)
            .then(res => {
                if (res.data.type == "project") {
                    Window.location.reload();
                }
                else {
                    dispatch(chatFetch(res.data));
                }
            }).catch(err => {
                localStorage.removeItem("lastChat");
                dispatch(chatError(err.response.data.reason));
            });
    }
};

export const createChat = (props) => {
    return async (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_KEY}/chat`)
            .then(res => {
                dispatch(chatFetch(res.data));
                window.location.href = `/chat?id=${res.data.id}`;
            }).catch(err => {
                localStorage.removeItem("lastChat");
                dispatch(chatError(err.response.data.reason));
            });
    }
};

export const postChat = (props) => {
    return async (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_KEY}/chat`, {
            id: props.id,
            message: props.message
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Token " + localStorage.getItem("authToken")
            }
        })
            .then(res => {
                dispatch(chatPost(res.data));
            }).catch(err => {
                localStorage.removeItem("lastChat");
                dispatch(chatError(err.response.data.reason));
            });
    }
};