import React, { useState } from "react";

import Logo from "../../../../assets/logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { logout } from "../../../../redux/actions/me";

const Option = ({ icon, text, expanded, to }) => {
    return (
        <Link className="navbar__option" to={to}>
            <span className="material-icons-outlined">{icon}</span>
            {
                expanded &&
                <div dangerouslySetInnerHTML={{ __html: text }} />
            }
        </Link>
    );

}

const Navbar = () => {
    const projects = useSelector(state => state.projects.data);

    const [showMenu, setShowMenu] = useState(false);

    return (
        localStorage.getItem("authToken") &&
        <nav className={showMenu ? "navbar navbar--expanded" : "navbar"}>
            <img
                className={showMenu ? "logo logo--medium mx-auto" : "logo logo--small mx-auto"}
                src={Logo}
                alt="Logo."
                onClick={() => setShowMenu(!showMenu)}
            />

            <div className="navbar__chats">
                <Option icon="add" text="New project" expanded={showMenu} to="/project-new" />

                {
                    projects?.map((project, index) =>
                        <Option key={index} icon="folder" text={`<b class="h6">Project ${project.name}</b><br/><p class="font-small m-0">${project.id}</p>`} expanded={showMenu} to={`/project?id=${project.id}`} />
                    )
                }
            </div>

            <div className="navbar__menu">
                <Option icon="home" text="Home" expanded={showMenu} to="/" />
                <Option icon="chat" text="Advise" expanded={showMenu} to="/chat" />
                <Option icon="person" text="Profile" expanded={showMenu} to="/profile" />
                <div className="navbar__option text-danger" onClick={() => logout()}>
                    <span className="material-icons-outlined">logout</span>
                    {
                        showMenu &&
                        "Log out"
                    }
                </div>
            </div>
        </nav>
    );
}

export default Navbar;