import React from "react";

import Logo from "../../../../assets/logo.png";

const Loader = () => {
  return (
    <div className="loader__parent">
      <img
        className="logo"
        src={Logo}
        alt="Logo."
      />

      <div className="loader__bar">
        <div className="loader__progress"></div>
      </div>
    </div>
  );
};

export default Loader;