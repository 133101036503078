import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { combineReducers } from 'redux';
import { accountReducer } from "./reducers/me";
import { projectsReducer } from "./reducers/projects";
import { chatReducer } from "./reducers/chat";
import { templatesReducer } from "./reducers/templates";
import { cloudReducer } from "./reducers/cloud";

const rootReducer = combineReducers({
    me: accountReducer,
    chat: chatReducer,
    cloud: cloudReducer,
    projects: projectsReducer,
    templates: templatesReducer
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;