import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";

const Index = ({ children, className }) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="layout">
            <Navbar />

            <div className={`layout--body ${className ? " " + className : ""}`}>
                {children}
            </div>
        </div>
    )
};

export default Index;