import {
    CLOUD_FETCH,
    CLOUD_FILE,
    CLOUD_POST,
    CLOUD_PUT,
    CLOUD_ERROR
} from '../actions/cloud';

import updateObject from "../updateObject";

const initialState = {
    error: "",
    file: {},
    data: {},
};

export function cloudFetch(state, action) {
    return updateObject(state, {
        data: action.payload
    });
}

export function cloudFile(state, action) {
    return updateObject(state, {
        file: action.payload
    });
}

export function cloudError(state, action) {
    return updateObject(state, {
        error: action.payload
    });
}

export function cloudPost(state, action) {
    return updateObject(state, {
        data: action.payload
    });
}

export function cloudPut(state, action) {
    return updateObject(state, {
        data: action.payload
    });
}

export function cloudReducer(state = initialState, action) {
    switch (action.type) {
        case CLOUD_FETCH: return cloudFetch(state, action);
        case CLOUD_FILE: return cloudFile(state, action);
        case CLOUD_ERROR: return cloudError(state, action);
        case CLOUD_POST: return cloudPost(state, action);
        case CLOUD_PUT: return cloudPut(state, action);
        default: return state;
    }
}