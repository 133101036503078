import axios from "axios";

export const CLOUD_FILE = 'CLOUD_FILE';
export const CLOUD_FETCH = 'CLOUD_FETCH';
export const CLOUD_POST = 'CLOUD_POST';
export const CLOUD_PUT = 'CLOUD_PUT';
export const CLOUD_ERROR = 'CLOUD_ERROR';

export function cloudError(payload) {
    return {
        type: CLOUD_ERROR,
        payload: payload
    };
}

export function cloudFile(payload) {
    return {
        type: CLOUD_FILE,
        payload: payload
    };
}

export function cloudFetch(payload) {
    return {
        type: CLOUD_FETCH,
        payload: payload
    };
}

export function cloudPost(payload) {
    return {
        type: CLOUD_POST,
        payload: payload
    };
}

export function cloudPut(payload) {
    return {
        type: CLOUD_PUT,
        payload: payload
    };
}

export const getCloud = (props) => {
    return async (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_KEY}/cloud?project=${props.project}`)
        .then(res => {
            dispatch(cloudFetch(res.data));
        }).catch(err => {
            dispatch(cloudError(err.response.data.reason));
        });
    }
};

export const getFileCloud = (props) => {
    return async (dispatch) => {
        axios.get(`${process.env.REACT_APP_API_KEY}/cloud?project=${props.project}&path=${props.path}`)
        .then(res => {
            dispatch(cloudFile({...props, content: res.data}));
        }).catch(err => {
            dispatch(cloudError(err.response.data.reason));
        });
    }
};

export const postCloud = (props) => {
    return async (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_KEY}/cloud`, {
            project: props.project,
            path: props.path,
            content: props.content
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Token " + localStorage.getItem("authToken")
            }
        })
        .then(res => {
            dispatch(cloudPost(res.data));
        }).catch(err => {
            dispatch(cloudError(err.response.data.reason));
        });
    }
};

export const putCloud = (props) => {
    return async (dispatch) => {
        axios.put(`${process.env.REACT_APP_API_KEY}/cloud`, {
            project: props.project,
            path: props.path,
            content: props.content,
            oldPath: props.oldPath,
            newPath: props.newPath,
            action: props.action
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Token " + localStorage.getItem("authToken")
            }
        })
        .then(res => {
            dispatch(cloudPut(res.data));
        }).catch(err => {
            dispatch(cloudError(err.response.data.reason));
        });
    }
};

export const deleteCloud = (props) => {
    return async (dispatch) => {
        axios.delete(`${process.env.REACT_APP_API_KEY}/cloud`, {
            project: props.project,
            path: props.path
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Token " + localStorage.getItem("authToken")
            }
        })
        .then(res => {
            window.location.reload();
        }).catch(err => {
            dispatch(cloudError(err.response.data.reason));
        });
    }
};