import axios from "axios";
import { SHA256 } from "crypto-js";

export const LOGIN_STARTING = 'LOGIN_STARTING';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const REGISTER_STARTING = 'REGISTER_STARTING';
export const REGISTER_SUCCESSFULL = 'REGISTER_SUCCESSFULL';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export function loginStarting(payload) {
    return {
        type: LOGIN_STARTING,
        payload: payload
    };
}

export function loginSuccessfull(payload) {
    return {
        type: LOGIN_SUCCESSFULL,
        payload: payload
    };
}

export function loginFailed(payload) {
    return {
        type: LOGIN_FAILED,
        payload: payload
    };
}

export const login = (props) => {
    return async (dispatch) => {
        dispatch(loginStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/users?email=${props.email}&password=${SHA256(props.password)}`)
        .then(res => {
            dispatch(loginSuccessfull(res.data));
            localStorage.setItem("authToken", res.data.token);
            window.location.reload();
        }).catch(err => {
            dispatch(loginFailed(err.response.data.reason));
        });
    }
};

export function registerStarting(payload) {
    return {
        type: REGISTER_STARTING,
        payload: payload
    };
}

export function registerSuccessfull(payload) {
    return {
        type: REGISTER_SUCCESSFULL,
        payload: payload
    };
}

export function registerFailed(payload) {
    return {
        type: REGISTER_FAILED,
        payload: payload
    };
}

export const register = (props) => {
    return async (dispatch) => {
        dispatch(registerStarting());

        axios.post(`${process.env.REACT_APP_API_KEY}/users`, {
            ...props,
            password: String(SHA256(props.password))
        })
        .then(res => {
            dispatch(registerSuccessfull(res.data));
            localStorage.setItem("authToken", res.data.token);
            window.location.reload();
        }).catch(err => {
            dispatch(registerFailed(err.response.data.reason));
        });
    }
};

export const CHECKIN_STARTING = 'CHECKIN_STARTING';
export const CHECKIN_SUCCESSFULL = 'CHECKIN_SUCCESSFULL';
export const CHECKIN_FAILED = 'CHECKIN_FAILED';

export function checkinStarting(payload) {
    return {
        type: CHECKIN_STARTING,
        payload: payload
    };
}

export function checkinSuccessfull(payload) {
    return {
        type: CHECKIN_SUCCESSFULL,
        payload: payload
    };
}

export function checkinFailed(payload) {
    return {
        type: CHECKIN_FAILED,
        payload: payload
    };
}

export const checkin = (props) => {
    return async (dispatch) => {
        dispatch(checkinStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/users?token=${localStorage.getItem("authToken")}`)
        .then(res => {
            dispatch(checkinSuccessfull(res.data));
        }).catch(err => {
            localStorage.clear();
            dispatch(checkinFailed(err.response.data.reason));
        });
    }
};

export const NEW_TOKEN_STARTING = 'NEW_TOKEN_STARTING';
export const NEW_TOKEN_SUCCESSFULL = 'NEW_TOKEN_SUCCESSFULL';
export const NEW_TOKEN_FAILED = 'NEW_TOKEN_FAILED';

export function newTokenStarting(payload) {
    return {
        type: NEW_TOKEN_STARTING,
        payload: payload
    };
}

export function newTokenSuccessfull(payload) {
    return {
        type: NEW_TOKEN_SUCCESSFULL,
        payload: payload
    };
}

export function newTokenFailed(payload) {
    return {
        type: NEW_TOKEN_FAILED,
        payload: payload
    };
}

export const changeData = (props) => {
    return async (dispatch) => {
        dispatch(newTokenStarting());
        dispatch(checkinStarting());

        axios.put(`${process.env.REACT_APP_API_KEY}/users`,
        {
            ...props
        }, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem("authToken")}`
            }
        })
        .then(res => {
            dispatch(newTokenSuccessfull(res.data));
            dispatch(checkinSuccessfull(res.data));
            alert('Your account has been updated.');
            window.location.reload();
        }).catch(err => {
            dispatch(checkinFailed(err.response.data.reason));
            dispatch(newTokenFailed(err.response.data.reason));
        });
    }
};

export const logout = () => {
    localStorage.clear();
    window.location.reload();
}