import {
    PROJECTS_ERROR,
    PROJECTS_FORM,
    PROJECTS_FETCH,
    PROJECTS_POST,
    PROJECTS_PUT
} from '../actions/projects';

import updateObject from "../updateObject";

const initialState = {
    error: "",
    data: {},
    form: []
};

export function projectsFetch(state, action) {
    return updateObject(state, {
        data: action.payload
    });
}

export function projectsForm(state, action) {
    return updateObject(state, {
        form: action.payload
    });
}

export function projectsError(state, action) {
    return updateObject(state, {
        error: action.payload
    });
}

export function projectsPost(state, action) {
    return updateObject(state, {
    });
}

export function projectsPut(state, action) {
    return updateObject(state, {
    });
}

export function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case PROJECTS_FETCH: return projectsFetch(state, action);
        case PROJECTS_FORM: return projectsForm(state, action);
        case PROJECTS_ERROR: return projectsError(state, action);
        case PROJECTS_POST: return projectsPost(state, action);
        case PROJECTS_PUT: return projectsPut(state, action);
        default: return state;
    }
}